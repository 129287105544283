<template>
    <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        scrollable
        transition="dialog-bottom-transition"
        class="dialog-activator--full py-2 px-2"
    >
        <template v-slot:activator="{ on }">
            <v-btn
                v-on="on"
                outlined
                block
                depressed
                color="primary"
                class="my-0 btn--text-wrap text-left"
                v-text="modifyPersonalDataTitle"
            />
        </template>
        <v-card>
            <v-card-title class="pa-0">
                <v-toolbar dark fixed color="primary">
                    <v-toolbar-title v-text="dialogTitle" />
                    <v-spacer></v-spacer>
                    <div>
                        <v-btn
                            color="white"
                            outlined
                            fab
                            small
                            :elevation="2"
                            class="ma-2"
                            @click.native="closeDialog">
                            <v-icon>close</v-icon>
                        </v-btn>
                    </div>
                </v-toolbar>
            </v-card-title>
            <v-card-text flat tile>
                <v-alert text class="mt-3" border="left" dense type="info" icon="mdi-information-outline">
                    <span
                        v-text="dialogDescription"
                    />
                </v-alert>
                <div v-for="(groupOfPerson, j) in model" :key="j">
                    <v-toolbar short flat color="transparent">
                        <v-toolbar-title class="px-2">
                        <v-btn v-if="getGroupOfPersonData(groupOfPerson.groupId).length" :disabled="disabled" icon color="primary" @click="toggleCheckboxGroup(groupOfPerson.groupId)"><v-icon>mdi-check-box-multiple-outline</v-icon></v-btn>
                        {{ getGroupOfPersonTitle(groupOfPerson.groupId) || 'N/A' }}
                        </v-toolbar-title>
                    </v-toolbar>
                    <div>
                        <v-container fluid>
                            <v-row>
                                <v-col
                                    v-if="!getGroupOfPersonData(groupOfPerson.groupId).length"
                                    cols="12"
                                >
                                    <v-alert
                                        class="mt-3"
                                        type="warning"
                                        outlined
                                        border="left"
                                        >{{
                                            $t('vvtProcessEditor.recipients.personalData.noDataSelected')
                                        }}</v-alert
                                    >
                                </v-col>
                                <template v-else>
                                    <v-col
                                        cols="12"
                                        sm="6"
                                        lg="4"
                                        xl="3"
                                        v-for="(personalData,
                                        k) in getGroupOfPersonData(
                                            groupOfPerson.groupId
                                        )"
                                        :key="k"
                                    >
                                        <BaseCheckboxButton
                                            :title="personalData.title"
                                            :value="
                                                getValue(
                                                    personalData.id ||
                                                        personalData.clientId,
                                                    groupOfPerson.personalData
                                                )
                                            "
                                            @input="
                                                onToggle(
                                                    $event,
                                                    personalData.id ||
                                                        personalData.clientId,
                                                    groupOfPerson.groupId
                                                )
                                            "
                                            :disabled="disabled"
                                        />
                                    </v-col>
                                </template>
                            </v-row>
                        </v-container>
                    </div>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { kebabCase } from 'lodash';

export default {
    name: 'RecipientPersonalData',
    i18n: {
        messages: {
            en: require('@/locales/vvt/ProcessEditor/en.json'),
            de: require('@/locales/vvt/ProcessEditor/de.json'),
        },
    },
    props: {
        value: {
            type: Array,
            default: () => []
        },
        i: {
            type: Number,
            default: null
        },
        helpMode: {
            type: Boolean,
            default: false,
        },
        groupsOfPersonItems: {
            type: Array,
            default: () => []
        },
        groupsOfPersonSelectedItems: {
            type: Array,
            default: () => []
        },
        personalDataItems: {
            type: Array,
            default: () => []
        },
        label: {
            type: String,
            default: null,
        },
        dialogTitle: {
            type: String,
            default: null,
        },
        dialogDescription: {
            type: String,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            name: kebabCase(this.$options.name),
            dialog: false,
        };
    },
    methods: {
        toggleCheckboxGroup (groupId) {
            let group = this.model.find(x => x.groupId === groupId);
            let groupData = this.getGroupOfPersonData(groupId);
            if (!group.personalData || !group.personalData.length) {
                group.personalData = groupData.map(x => x.id).sort().slice();
            } else if (group.personalData.length !== groupData.length) {
                group.personalData = groupData.map(x => x.id).sort().slice();
            } else {
                group.personalData = [];
            }
            this.$emit('input', this.model);
        },
        getValue(id, personalData) {
            return personalData ? personalData.includes(id) : false;
        },
        closeDialog() {
            this.dialog = false;
        },
        getGroupOfPersonTitle(id) {
            const item = this.groupsOfPersonSelectedItems.find(
                x => x.id === id || x.clientId === id
            );
            if (item && item.group && item.group.title) {
                return item.group.title;
            }
            if (item && item.group && item.group.id) {
                const groupItem = this.groupsOfPersonItems.find(
                    x => x.id === item.group.id
                );
                if (groupItem && groupItem.title) {
                    return groupItem.title;
                }
            }
            return null;
        },
        getGroupOfPersonData(id) {
            const group = this.groupsOfPersonSelectedItems.find(
                x => x.id === id || x.clientId === id
            );
            if (group && group.personalData) {
                return this.personalDataItems.filter(x =>
                    group.personalData
                        ? group.personalData.includes(x.id || x.clientId)
                        : false
                );
            }
            return [];
        },
        onToggle(toggleState, personalDataId, groupOfPersonId) {
            const recipient = this.model.find(
                x => x.groupId === groupOfPersonId
            );
            if (
                !toggleState &&
                recipient.personalData &&
                recipient.personalData.includes(personalDataId)
            ) {
                recipient.personalData = recipient.personalData.filter(
                    x => x !== personalDataId
                );
            }

            if (
                toggleState &&
                !recipient.personalData.includes(personalDataId)
            ) {
                recipient.personalData.push(personalDataId);
            }
            this.$emit('input', this.model);
        },
    },
    computed: {
        modifyPersonalDataTitle () {
            return this.label + ' (' + this.$tc('amountDataFields', this.countPersonalData) + ')';
        },
        countPersonalData() {
            let amount = 0;
            this.model.forEach(x => {
                amount += x.personalData ? x.personalData.length : 0;
            });
            return amount;
        },
        model: {
            get() {
                return this.value.filter(x => typeof x === 'object' && x !== null);
            },
            set(val) {
                this.$emit('input', val);
            },
        },
    },
};
</script>
