import { v4 as uuid } from 'uuid';
export default {
    methods: {
       createRecipientPersonalData (val) {
            val.forEach(groupId => {
                const existingIndex = this.recipient.personalData.findIndex(x => x.groupId === groupId);
                if (existingIndex < 0) {
                    this.recipient.personalData.push({
                        id: null,
                        clientId: uuid(),
                        groupId: groupId,
                        personalData: this.groupsOfPersonSelectedItems.find(
                                        x => x.id === groupId || x.clientId === groupId
                                    ).personalData
                    })
                }
            });

            this.recipient.personalData.forEach((x, index) => {
                if(!val.includes(x.groupId)) {
                    this.$delete(this.recipient.personalData, index);
                }
            });         
        }
    },
};
