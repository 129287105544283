<template>
    <div
        @click.passive="emitHelpMode"
        @focus.capture="emitHelpMode"
    >
        <div style="position:relative" class="pt-1">
            <v-row
                class="pa-2 pt-0 pb-0 mb-1 fill-height"
                justify="space-around"
            >
                <v-col cols="12">
                    <v-card-title
                        class="pl-0 pb-0 text-subtitle-1"
                        v-t="'legitimateInterest.title'"
                    />
                </v-col>
                <v-col cols="12">
                    <SzenarioSelect
                        :disabled="disabled"
                        :i="i"
                        :form-scope="formScope"
                        :validation-name="formScope + '-szenario-select-' + i"
                        :original-id="originalId"
                        :template-name="templateName"
                        :current-ui-language="currentUiLanguage"
                        :drawer="categoryDrawer"
                        :translatable="translatable"
                        @dismantleSzenario="dismantleSzenario()"
                        @set-drawer="categoryDrawer = $event"
                        @saveSzenario="saveSzenario($event)"
                    />
                </v-col>
                <v-col cols="12">
                    <v-card-title
                        class="pl-0 pb-0 text-subtitle-2"
                        v-t="'legitimateInterest.interestHeadline'"
                    />
                </v-col>
                <v-col
                    cols="12"
                    md="6"
                    :class="{ 'pr-1': $vuetify.breakpoint.mdAndUp }"
                >
                    <LeaTextArea
                        v-if="!translatable"
                        :disabled="disabled"
                        :formScope="formScope"
                        :step="3"
                        :rowId="rowId"
                        :fieldName="fieldName"
                        childFieldName="interestResponsiblePerson"
                        :label="$t('legitimateInterest.items[0].label')"
                        :hint="$t('legitimateInterest.items[0].hint')"
                        :helpModeText="$t('legitimateInterest.items[0].helpMode')"
                        v-model="interestResponsiblePersonModel"
                        rows="2"
                        @help-mode="$emit('help-mode', $event)"
                        :ref="formScope + '_' + componentScope + '_interestResponsiblePerson_' + rowId"
                    />
                    <LeaTranslationTableInput
                        v-else
                        v-model="interestResponsiblePersonModel"
                        @modified="$emit('modified', $event)"
                        fieldValue="interestResponsiblePerson"
                        :fieldText="$t('legitimateInterest.items[0].label')"
                        :helpModeText="$t('legitimateInterest.items[0].helpMode')"
                        fieldType="textarea"
                        childFieldName="interestResponsiblePerson"
                        @help-mode="$emit('help-mode', $event)"
                        :rowId="rowId"
                        :ref="formScope + '_' + componentScope + '_interestResponsiblePerson_' + rowId"
                    />
                </v-col>
                <v-col
                    cols="12"
                    md="6"
                    :class="{ 'pl-1': $vuetify.breakpoint.mdAndUp }"
                >
                    <LeaTextArea
                        v-if="!translatable"
                        :disabled="disabled"
                        :formScope="formScope"
                        :step="3"
                        :rowId="rowId"
                        :fieldName="fieldName"
                        childFieldName="interestPersonConcerned"
                        :label="$t('legitimateInterest.items[1].label')"
                        :hint="$t('legitimateInterest.items[1].hint')"
                        :helpModeText="$t('legitimateInterest.items[1].helpMode')"
                        v-model="interestPersonConcernedModel"
                        rows="2"
                        @help-mode="$emit('help-mode', $event)"
                        :ref="formScope + '_' + componentScope + '_interestPersonConcerned_' + rowId"
                    />
                    <LeaTranslationTableInput
                        v-else
                        v-model="interestPersonConcernedModel"
                        @modified="$emit('modified', $event)"
                        fieldValue="interestPersonConcerned"
                        :fieldText="$t('legitimateInterest.items[1].label')"
                        :helpModeText="$t('legitimateInterest.items[1].helpMode')"
                        fieldType="textarea"
                        childFieldName="interestPersonConcerned"
                        @help-mode="$emit('help-mode', $event)"
                        :rowId="rowId"
                        :ref="formScope + '_' + componentScope + '_interestPersonConcerned_' + rowId"
                    />
                </v-col>
                <v-col cols="12">
                    <LeaTextArea
                        v-if="!translatable"
                        :disabled="disabled"
                        :formScope="formScope"
                        :step="3"
                        :rowId="rowId"
                        :fieldName="fieldName"
                        childFieldName="necessity"
                        :label="$t('legitimateInterest.items[2].label')"
                        :hint="$t('legitimateInterest.items[2].hint')"
                        :helpModeText="$t('legitimateInterest.items[2].helpMode')"
                        v-model="necessityModel"
                        rows="2"
                        @help-mode="$emit('help-mode', $event)"
                        :ref="formScope + '_' + componentScope + '_necessity_' + rowId"
                    />
                    <LeaTranslationTableInput
                        v-else
                        v-model="necessityModel"
                        @modified="$emit('modified', $event)"
                        fieldValue="necessity"
                        :fieldText="$t('legitimateInterest.items[2].label')"
                        :helpModeText="$t('legitimateInterest.items[2].helpMode')"
                        fieldType="textarea"
                        childFieldName="necessity"
                        @help-mode="$emit('help-mode', $event)"
                        :rowId="rowId"
                        :ref="formScope + '_' + componentScope + '_necessity_' + rowId"
                    />
                </v-col>
                <v-col cols="12">
                    <LeaTextArea
                        v-if="!translatable"
                        :disabled="disabled"
                        :formScope="formScope"
                        :step="3"
                        :rowId="rowId"
                        :fieldName="fieldName"
                        childFieldName="balancingOfInterests"
                        :label="$t('legitimateInterest.items[3].label')"
                        :hint="$t('legitimateInterest.items[3].hint')"
                        :helpModeText="$t('legitimateInterest.items[3].helpMode')"
                        v-model="balancingOfInterestsModel"
                        rows="2"
                        @help-mode="$emit('help-mode', $event)"
                        :ref="formScope + '_' + componentScope + '_balancingOfInterests_' + rowId"
                    />
                    <LeaTranslationTableInput
                        v-else
                        v-model="balancingOfInterestsModel"
                        @modified="$emit('modified', $event)"
                        fieldValue="balancingOfInterests"
                        :fieldText="$t('legitimateInterest.items[3].label')"
                        :helpModeText="$t('legitimateInterest.items[3].helpMode')"
                        fieldType="textarea"
                        childFieldName="balancingOfInterests"
                        @help-mode="$emit('help-mode', $event)"
                        :rowId="rowId"
                        :ref="formScope + '_' + componentScope + '_balancingOfInterests_' + rowId"
                    />
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import { v4 as uuid } from 'uuid';
import { mapGetters } from 'vuex';
import LeaTextArea from '@/components/Input/LeaTextArea';
import ModelMixin from '@/components/vvt/ModelMixin';
import SzenarioSelect from './SzenarioSelect';
import LeaTranslationTableInput from '@/components/Input/LeaTranslationTableInput';

export default {
    mixins: [ModelMixin],
    components: {
        LeaTextArea,
        SzenarioSelect,
        LeaTranslationTableInput,
    },
    model: {
        prop: 'model',
        event: 'change'
    },
    props: {
        value: {
            type: Object,
            default: () => {}
        },
        model: {
            type: Object,
            default: () => {}
        },
        fieldName: {
            type: String,
            default: null,
        },
        childFieldName: {
            type: String,
            default: null,
        },
        helpModeText: {
            type: String,
            default: null,
        },
        formScope: {
            type: String,
            default: null
        },
        componentScope: {
            type: String,
            default: null
        },
        i: {
            type: Number,
            default: null
        },
        rowId: {
            type: [Number, String],
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
        validationName: {
            type: String,
            default: null
        },
        groupName: {
            type: String,
            default: null
        },
        currentUiLanguage: {
            type: String,
            default: null
        },
        translatable: {
            type: Boolean,
            default: false
        },
        availableLanguages: {
            type: Array,
            default: () => ['de', 'en']
        },
    },
    data () {
        return {
            categoryDrawer: true
        }
    },
    computed: {
        ...mapGetters({
            workflowMode: 'processingActivityModel/getWorkflowModeState'
        }),
        originalId() {
            return this.model && this.model.originalId
                ? this.model.originalId
                : null;
        },
        templateName() {
            return this.model && this.model.title
                ? this.model.title
                : null;
        },
        interestResponsiblePersonModel: {
            get() {
                return this.translatable ? this.model.translations : this.model.interestResponsiblePerson;
            },
            set(val) {
                this.$emit('change', {
                    ...this.model,
                    ...{
                        id: uuid(),
                        originalId: this.originalId,
                        interestResponsiblePerson: val,
                    },
                });
            }
        },
        interestPersonConcernedModel: {
            get() {
                return this.translatable ? this.model.translations : this.model.interestPersonConcerned;
            },
            set(val) {
                this.$emit('change', {
                    ...this.model,
                    ...{
                        id: uuid(),
                        originalId: this.originalId,
                        interestPersonConcerned: val,
                    },
                });
            }
        },
        necessityModel: {
            get() {
                return this.translatable ? this.model.translations : this.model.necessity;
            },
            set(val) {
                this.$emit('change', {
                    ...this.model,
                    ...{
                        id: uuid(),
                        originalId: this.originalId,
                        necessity: val,
                    },
                });
            }
        },
        balancingOfInterestsModel: {
            get() {
                return this.translatable ? this.model.translations : this.model.balancingOfInterests;
            },
            set(val) {
                this.$emit('change', {
                    ...this.model,
                    ...{
                        id: uuid(),
                        originalId: this.originalId,
                        balancingOfInterests: val,
                    },
                });
            }
        },
    },
    methods: {
        saveSzenario(val) {
            this.$emit('change', val);
        },
        dismantleSzenario() {
            this.$emit('change', {
                ...this.model,
                ...{ originalId: null, title: null },
            });
        },
        calculateInputHeight() {
            this.$nextTick().then(() => {
                const ref1 = this.$refs[
                    this.formScope +
                        '_' +
                        this.componentScope +
                        '_interestResponsiblePerson_' +
                        this.rowId
                ];
                const ref2 = this.$refs[
                    this.formScope +
                        '_' +
                        this.componentScope +
                        '_interestPersonConcerned_' +
                        this.rowId
                ];
                const ref3 = this.$refs[
                    this.formScope +
                        '_' +
                        this.componentScope +
                        '_necessity_' +
                        this.rowId
                ];
                const ref4 = this.$refs[
                    this.formScope +
                        '_' +
                        this.componentScope +
                        '_balancingOfInterests_' +
                        this.rowId
                ];
                ref1.calculateInputHeight();
                ref2.calculateInputHeight();
                ref3.calculateInputHeight();
                ref4.calculateInputHeight();
            });
        },
        emitHelpMode () {
            if (this.helpModeText) {
                this.$emit('help-mode', {
                    title: this.$t('legitimateInterest.title'),
                    text: this.$t('legitimateInterest.helpMode'),
                    fieldName: this.fieldName,
                    childFieldName: this.childFieldName
                })
            }
        }
    },
};
</script>
