var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{staticClass:"dialog-activator--full py-2 px-2",attrs:{"fullscreen":"","hide-overlay":"","scrollable":"","transition":"dialog-bottom-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"my-0 btn--text-wrap text-left",attrs:{"outlined":"","block":"","depressed":"","color":"primary"},domProps:{"textContent":_vm._s(_vm.modifyPersonalDataTitle)}},on))]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"pa-0"},[_c('v-toolbar',{attrs:{"dark":"","fixed":"","color":"primary"}},[_c('v-toolbar-title',{domProps:{"textContent":_vm._s(_vm.dialogTitle)}}),_c('v-spacer'),_c('div',[_c('v-btn',{staticClass:"ma-2",attrs:{"color":"white","outlined":"","fab":"","small":"","elevation":2},nativeOn:{"click":function($event){return _vm.closeDialog.apply(null, arguments)}}},[_c('v-icon',[_vm._v("close")])],1)],1)],1)],1),_c('v-card-text',{attrs:{"flat":"","tile":""}},[_c('v-alert',{staticClass:"mt-3",attrs:{"text":"","border":"left","dense":"","type":"info","icon":"mdi-information-outline"}},[_c('span',{domProps:{"textContent":_vm._s(_vm.dialogDescription)}})]),_vm._l((_vm.model),function(groupOfPerson,j){return _c('div',{key:j},[_c('v-toolbar',{attrs:{"short":"","flat":"","color":"transparent"}},[_c('v-toolbar-title',{staticClass:"px-2"},[(_vm.getGroupOfPersonData(groupOfPerson.groupId).length)?_c('v-btn',{attrs:{"disabled":_vm.disabled,"icon":"","color":"primary"},on:{"click":function($event){return _vm.toggleCheckboxGroup(groupOfPerson.groupId)}}},[_c('v-icon',[_vm._v("mdi-check-box-multiple-outline")])],1):_vm._e(),_vm._v(" "+_vm._s(_vm.getGroupOfPersonTitle(groupOfPerson.groupId) || 'N/A')+" ")],1)],1),_c('div',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[(!_vm.getGroupOfPersonData(groupOfPerson.groupId).length)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{staticClass:"mt-3",attrs:{"type":"warning","outlined":"","border":"left"}},[_vm._v(_vm._s(_vm.$t('vvtProcessEditor.recipients.personalData.noDataSelected')))])],1):_vm._l((_vm.getGroupOfPersonData(
                                        groupOfPerson.groupId
                                    )),function(personalData,k){return _c('v-col',{key:k,attrs:{"cols":"12","sm":"6","lg":"4","xl":"3"}},[_c('BaseCheckboxButton',{attrs:{"title":personalData.title,"value":_vm.getValue(
                                                personalData.id ||
                                                    personalData.clientId,
                                                groupOfPerson.personalData
                                            ),"disabled":_vm.disabled},on:{"input":function($event){return _vm.onToggle(
                                                $event,
                                                personalData.id ||
                                                    personalData.clientId,
                                                groupOfPerson.groupId
                                            )}}})],1)})],2)],1)],1)],1)})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }