<template>
    <div>
        <v-alert
            :value="szenarioSelected"
            prominent
            color="success"
            icon="check_circle"
            outlined
        >
            <v-row align="center">
                <v-col class="grow">{{ $t('legitimateInterest.basedOnScenario') }} {{ templateName }}</v-col>
                <v-col class="shrink">
                    <v-btn
                        :disabled="disabled"
                        color="error"
                        depressed
                        @click.stop="dismantleSzenario()"
                    >
                        <v-icon left>clear</v-icon>
                        {{ $t('legitimateInterest.dismantleSzenario') }}
                    </v-btn>
                </v-col>
            </v-row>
        </v-alert>
        <v-alert :value="!szenarioSelected" prominent color="info" icon="mdi-information-outline" outlined>
            <v-row align="center">
                <v-col class="grow">{{
                    $t('legitimateInterest.selectSzenario')
                }}</v-col>
                <v-col class="shrink">
                    <v-btn
                        :disabled="disabled || $wait.is('fetch legitimate interest list')"
                        color="success"
                        depressed
                        @click.stop="openSzenarioSelection()"
                        :loading="$wait.is('fetch legitimate interest list')"
                    ><v-icon left>open_in_browser</v-icon>
                        {{  $t('legitimateInterest.selectSzenarioButtonText') }}
                    </v-btn>
                </v-col>
            </v-row>
        </v-alert>
        <v-dialog
            v-model="szenarioDialogModel"
            fullscreen
            hide-overlay
            scrollable
            transition="dialog-bottom-transition"
        >
            <v-card>
                <v-card-title class="pa-0">
                    <v-toolbar dark fixed color="primary">
                        <v-toolbar-title v-t="'legitimateInterest.scenario.label'" />
                        <v-spacer />
                        <div>
                            <v-btn
                                v-if="$vuetify.breakpoint.smAndDown"
                                color="white"
                                outlined
                                fab
                                small
                                :elevation="2"
                                class="ma-2"
                                @click.stop="drawerModel = !drawerModel">
                                    <v-icon v-text="'mdi-format-list-bulleted-square'" />
                            </v-btn>
                            <v-btn 
                                color="white"
                                outlined
                                fab
                                small
                                :elevation="2"
                                class="ma-2"
                                @click.native="closeSzenarioSelection()">
                                <v-icon>close</v-icon>
                            </v-btn>
                        </div>
                    </v-toolbar>
                </v-card-title>
                <v-card-text class="pa-0">
                    <v-container fluid class="py-0">
                        <v-row>
                            <v-col cols="12" md="4" lg="3" xl="2">
                                <v-navigation-drawer
                                    v-model="drawerModel"
                                    :permanent="$vuetify.breakpoint.mdAndUp"
                                    fixed
                                    hide-overlay
                                    class="v-navigation-drawer--open"
                                    :class="{'mt-16': $vuetify.breakpoint.mdAndUp, 'mt-14': $vuetify.breakpoint.smAndDown}"
                                    :style="drawerStyle"
                                    >
                                        <v-list
                                            :dense="true"
                                            color="transparent"
                                            :two-line="false"
                                        >
                                            <v-subheader class="primary--text mb-3 px-0 pl-4 pt-3">
                                                <span
                                                    :class="{
                                                        'text-body-2': $vuetify.breakpoint.lgAndDown,
                                                        'text-subtitle-1': $vuetify.breakpoint.xl,
                                                    }"
                                                    class="font-weight-bold text-truncate"
                                                    v-t="'legitimateInterest.categories'"
                                                />
                                            </v-subheader>
                                            <v-list-item
                                                @click="selectCategory(-1)"
                                                href
                                                tag="a"
                                                color="primary"
                                            >
                                                <template v-slot:default>
                                                    <v-list-item-content>
                                                        <div :class="categoryClassObject(-1)">{{ $t('all') }}</div>
                                                    </v-list-item-content>
                                                </template>
                                            </v-list-item>
                                            <v-list-item
                                                v-for="category in items"
                                                :key="category.id"
                                                @click="selectCategory(category.id)"
                                                href
                                                tag="a"
                                                color="primary"
                                            >
                                                <template v-slot:default>
                                                    <v-list-item-content>
                                                        <div :class="categoryClassObject(category.id)">
                                                            <v-list-item-title v-text="category.title" />
                                                            <v-list-item-subtitle>({{ category.items.length }})</v-list-item-subtitle>
                                                        </div>
                                                    </v-list-item-content>
                                                </template>
                                            </v-list-item>
                                        </v-list>
                                </v-navigation-drawer>
                            </v-col>
                            <v-col cols="12" md="8" lg="9" xl="10">
                                <div
                                    v-for="category in filteredItems"
                                    :key="category.title"
                                >
                                    <div v-if="category.items && category.items.length">
                                        <v-container
                                            fluid
                                            class="py-0 pl-0"
                                        >
                                            <v-row>
                                                <v-col cols="12">
                                                    <v-subheader
                                                        class="primary--text mb-3 px-0 pt-2"
                                                    >
                                                        <span
                                                            :class="{
                                                                'text-body-2': $vuetify.breakpoint.lgAndDown,
                                                                'text-subtitle-1': $vuetify.breakpoint.xl,
                                                            }"
                                                            class="font-weight-bold text-truncate"
                                                            v-t="category.title"
                                                            />
                                                    </v-subheader>
                                                </v-col>
                                                <v-col
                                                    cols="12"
                                                    sm="6"
                                                    lg="4"
                                                    xl="3"
                                                    v-for="szenario in category.items"
                                                    :key="szenario.title"
                                                >
                                                    <v-tooltip
                                                        bottom
                                                        color="primary"
                                                    >
                                                        <template v-slot:activator="{ on }">
                                                            <v-btn
                                                                :disabled="disabled || $wait.waiting('fetch data for id ' + szenario.id)"
                                                                v-on="on"
                                                                outlined
                                                                block
                                                                class="v-btn-truncate"
                                                                :loading="$wait.waiting('fetch data for id ' + szenario.id)"
                                                                color="primary"
                                                                @click="selectSzenario(szenario.id)"
                                                            >
                                                                <div
                                                                    class="text-left text-smaller"
                                                                    v-t="szenario.title"
                                                                />
                                                                <v-icon
                                                                    right
                                                                    :class="{
                                                                        'primary--text': !$vuetify.dark,
                                                                        'secondary--text': $vuetify.dark,
                                                                    }"
                                                                    >get_app</v-icon
                                                                >
                                                            </v-btn>
                                                        </template>
                                                        <span v-t="szenario.title" />
                                                    </v-tooltip>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>
        <BaseConfirmationDialog
            v-model="overwriteWarningDialogModel"
            :title="$t('legitimateInterest.dialogHeadline')"
            :text="$t('legitimateInterest.dialogText')"
            :cancelText="$t('cancel')"
            :confirmationText="$t('continue')"
            buttonColorConfirm="warning"
            @confirm="overwriteSzenario()"
            @cancel="closeOverwriteWarning()"
        />
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import http from '@/utils/axios';

export default {
    props: {
        formScope: {
            type: String,
            default: null
        },
        originalId: {
            type: Number,
            default: null
        },
        currentUiLanguage: {
            type: String,
            default: 'en'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        drawer: {
            type: Boolean,
            default: true
        },
        templateName: {
            type: String,
            default: null
        },
        translatable: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            selectedCategory: -1,
            szenarioDialogModel: false,
            overwriteWarningDialogModel: false,
            selectedSzenario: null
        };
    },
    computed: {
        ...mapGetters({
            items: 'legitimateInterest/getCategoryList',
        }),
        drawerStyle () {
            let obj = {};
            if (this.$vuetify.breakpoint.mdAndUp) {
                obj.visibility = 'visible';
                obj.transform = 'none';
            }
            return obj;
        },
        drawerModel: {
            get() {
                return this.drawer;
            },
            set(val) {
                this.$emit('set-drawer', val);
            }
        },
        filteredItems() {
            if (this.selectedCategory === -1) {
                return this.items;
            }
            return this.items.filter(x => {
                return x.id === this.selectedCategory;
            });
        },
        szenarioSelected() {
            if (this.originalId) {
                return true;
            }
            return false;
        },
    },
    methods: {
        ...mapActions({
            fetchLegitimateInterestCategoryList: 'legitimateInterest/fetchCategoryList',
        }),
        dismantleSzenario() {
            this.$emit('dismantleSzenario');
            this.drawerModel = true;
        },
        openOverwriteWarning() {
            this.overwriteWarningDialogModel = true;
        },
        closeOverwriteWarning() {
            this.overwriteWarningDialogModel = false;
        },
        selectSzenario(szenarioId) {
            this.selectedSzenario = szenarioId;
            this.openOverwriteWarning();
        },
        overwriteSzenario() {
            this.loadSzenario(this.selectedSzenario);
            this.overwriteWarningDialogModel = false;
        },
        async openSzenarioSelection() {
            await this.fetchItems();
            this.$addClass(document.documentElement, 'hide-document-scrollbar');
            this.selectedSzenario = null;
            this.szenarioDialogModel = true;
            this.drawerModel = true;
        },
        async fetchItems() {
            this.$wait.start('fetch legitimate interest list');
            await this.fetchLegitimateInterestCategoryList({forcedLanguage: this.currentUiLanguage});
            this.$wait.end('fetch legitimate interest list');
        },
        closeSzenarioSelection() {
            this.szenarioDialogModel = false;
            this.drawerModel = true;
            this.$removeClass(document.documentElement, 'hide-document-scrollbar');
        },
        selectCategory(categoryId) {
            this.selectedCategory = categoryId;
        },
        isActive(categoryId) {
            return this.selectedCategory === categoryId;
        },
        categoryClassObject(categoryId) {
            return {
                'primary--text': this.isActive(categoryId),
            };
        },
        async loadSzenario(szenarioId) {
            const options = {
                headers: { 'Accept-Language': `${this.currentUiLanguage}` },
            };
            if (szenarioId) {
                this.$wait.start('fetch data for id ' + szenarioId);
                let apiRoute = this.translatable ? '/api/admin/legitimateInterest/' : '/api/legitimateInterest/';
                return http(apiRoute + szenarioId, options)
                    .then(response => response.data)
                    .then(response => {
                        response = {
                            ...response,
                            ...{ originalId: response.id },
                        };
                        this.$emit('saveSzenario', response);
                        this.closeSzenarioSelection();
                        this.$removeClass(document.documentElement, 'hide-document-scrollbar');
                        this.$wait.end('fetch data for id ' + szenarioId);
                    })
                    .catch(() => {
                        this.$wait.end('fetch data for id ' + szenarioId);
                        this.$removeClass(document.documentElement, 'hide-document-scrollbar');
                        throw new Error('Network exception');
                    });
            }
        },
    },
};
</script>
