<template>
    <v-card
        @click.passive="emitHelpMode"
        @focus.capture="emitHelpMode"
        height="100%"
        elevation="1"
    >
        <v-card-text class="px-0 py-0">
            <vue-dropzone
                :ref="'myVueDropzone' + i"
                :id="'dropzone' + i"
                :options="dropzoneOptions"
                v-model="model"
                v-on:vdropzone-sending="sendFile"
                v-on:vdropzone-success="addUpload"
                v-on:vdropzone-removed-file="removeUpload"
                class="theme--light"
            >
                ></vue-dropzone
            >
            <div
                class="v-messages v-messages--top-border theme--light pl-2 pb-2"
            >
                <div class="v-messages__wrapper">
                    <div class="v-messages__message">
                        {{ hint }}
                    </div>
                </div>
            </div>
            <v-list v-if="model.length">
                <v-list-item
                    v-for="file in model"
                    :key="file.uuid"
                    @click="$getFile(`/api/enclosures/download/${file.uuid}`, getFileName(file), true)"
                    :disabled="$wait.is('fetching file')"
                >
                    <v-list-item-action>
                        <v-icon color="primary">cloud_download</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title
                            v-text="getFileName(file)"
                        ></v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-tooltip left color="error">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    v-on="on"
                                    @click="removeItem(file)"
                                    color="error"
                                    icon
                                    :disabled="disabled"
                                >
                                <v-icon
                                    color="error"
                                    >mdi-close</v-icon
                                >
                                </v-btn>
                            </template>
                            <span>{{ removeHint }}</span>
                        </v-tooltip>
                    </v-list-item-action>
                </v-list-item>
            </v-list>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import vueDropzone from 'vue2-dropzone';
import { getToken } from '@/utils/auth';

export default {
    name: 'RecipientAvvUpload',
    components: {
        vueDropzone,
    },
    i18n: {
        messages: {
            en: require('@/locales/vvt/ProcessEditor/en.json'),
            de: require('@/locales/vvt/ProcessEditor/de.json'),
        },
    },
    props: {
        value: {
          type: Array,
          default: () => []
        },
        i: {
            type: Number,
            default: null,
        },
        rowId: {
            type: [Number, String],
            default: null,
        },
        label: {
            type: String,
            default: null,
        },
        hint: {
            type: String,
            default: null,
        },
        removeHint: {
            type: String,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        fieldName: {
            type: String,
            default: null,
        },
        childFieldName: {
            type: String,
            default: null,
        },
        helpModeText: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            dropzoneOptions: null,
        };
    },
    computed: {
        ...mapGetters({
            workflowMode: 'processingActivityModel/getWorkflowModeState'
        }),
        model: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        }
    },
    created() {
        const uploadHeaders = {
            'Cache-Control': null,
            'X-Requested-With': null,
            Accept: `application/json, application/prs.lea+json;v=${process.env.VUE_APP_VERSION}`
        }

        if (process.env.VUE_APP_SSO !== 'true') {
            uploadHeaders.Authorization = `Bearer ${getToken()}`
        }

        this.dropzoneOptions = {
            headers: uploadHeaders,
            dictDefaultMessage: this.label,
            addRemoveLinks: true,
            url: `${process.env.VUE_APP_BASE_URL}/api/enclosures/upload`,
            thumbnailHeight: 120,
            createImageThumbnails: false,
            maxFilesize: 20,
            acceptedFiles:
                'image/*,application/pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptm,.pptx',
        };
    },
    mounted () {
        if (this.disabled) {
            this.$refs['myVueDropzone' + this.i].disable();
        }
    },
    methods: {
        getFileName(file) {
            return this.$objectPropertyExists(file, 'fileName') ? file.fileName : file.filename;
        },
        removeItem(file) {
            this.model.splice(this.model.indexOf(file), 1);
            this.$emit('input', this.model);
        },
        sendFile(file, xhr, formData) {
            formData.append('clientId', file.upload.uuid);
            formData.append('type', 'DPA');
        },
        addUpload(file, response) {
            this.model.unshift({ ...file.upload, ...response });
            this.$emit('modified');
            this.$refs['myVueDropzone' + this.i].removeFile(file); //remove tile in upload area
            this.$emit('input', this.model);
        },
        removeUpload() { //removed tile in upload area
        },
        emitHelpMode () {
            if (this.helpModeText) {
                this.$emit('help-mode', {
                    title: this.label,
                    text: this.helpModeText,
                    fieldName: this.fieldName,
                    childFieldName: this.childFieldName
                })
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.dropzone {
    min-height: 120px;
    border-width: 0;
}
</style>
